export interface LearningCenterArticleLink {
  contentTypeId: 'learningCenterArticleLink';
  contentfulId: string;
  displayText: string;
  url: string;
}

export function isLearningCenterArticleLink(data: any): data is LearningCenterArticleLink {
  return data?.contentTypeId === 'learningCenterArticleLink';
}
