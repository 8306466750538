import React, { FC } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { ContentfulAssetFile } from '../ContentfulAssetFile/ContentfulAssetFile';
import { AssetFile } from '../ContentfulAssetFile/interfaces/asset-file.interface';
import Carousel from 'nuka-carousel';
import { P, Div, A, Hr } from '../Elements';
import { RelatedBlade } from '../../../contentful/blades';
import { ContentfulBladeQuote } from '../../../contentful/content-types/blades/blade-quote';
import { ContentfulAsset } from '../../../contentful/content-types/blades/common/interfaces/contentful-asset.interface';
import sectionPadding from '../BladePadding';
import { Lazy } from '../Lazy';
export function isContentfulBladeQuote(blade: RelatedBlade): blade is ContentfulBladeQuote {
  return blade.contentTypeId === 'bladeQuote';
}

interface QuoteBladeProps {
  blade: ContentfulBladeQuote;
}

interface LogoListParams {
  logoList: ContentfulAsset[] | undefined;
  logoListAssetFile: AssetFile[] | undefined;
}

function handleOnLoad() {
  window.dispatchEvent(new Event('resize'));
}

const LogoList: FC<LogoListParams> = ({ logoList, logoListAssetFile }) => {
  if (logoListAssetFile && logoListAssetFile.length > 0) {
    return (
      <Row className="pt7 pt9-l">
        {logoListAssetFile.map((assetFile, i) => (
          <Col
            className="flex items-center justify-center mb6-ns mb4"
            xs={6}
            lg={2}
            key={`quote-logo-list-asset-file-${i}`}
          >
            <ContentfulAssetFile className="mw-100 w-100" assetFile={assetFile} />
          </Col>
        ))}
      </Row>
    );
  } else if (logoList && logoList.length > 0) {
    return (
      <Row className="pt7 pt9-l">
        {logoList
          .filter(f => f.file)
          .map((f, i) => (
            <Col
              className="flex items-center justify-center mb6-ns mb4"
              xs={6}
              lg={3}
              key={`logo-list-asset-file-${i}`}
            >
              <Lazy>
                <img className="mw-100 w-100" title="logo" alt="logo" src={f.file?.publicURL} />
              </Lazy>
            </Col>
          ))}
      </Row>
    );
  }

  return null;
};

const QuoteSection: FC<QuoteBladeProps> = ({ blade }) => {
  const { image, imageAssetFile } = blade;
  return (
    <Col tabIndex={-1} xs={12} lg={{ offset: 2, span: 8 }} className="carousel-quote">
      <P marginTop={0} fontWeight={4} lineHeight={4} fontSize={[5, 6]}>
        {blade.quote}
      </P>
      <Div className="mt4 mt9-l flex-l flex-column flex-row-l items-end-l">
        <Div className="mr9-l flex flex-column">
          <P className="body-1 mv0 black">{blade.quoteAuthor}</P>
          <P marginTop={0} marginBottom={2} color={'black'} className="mv0-l fw7">
            {blade.quoteTitle}
          </P>
        </Div>
        <ContentfulAssetFile
          handleOnLoad={handleOnLoad}
          assetFile={imageAssetFile}
          fallBackUrl={blade.image?.file?.publicURL}
          className="db dib-l carousel-image"
        />
      </Div>

      {blade.learnMoreUrl && (
        <Div>
          <Hr
            backgroundColor="gray2"
            style={{ height: 1 }}
            border="none"
            marginTop={7}
            marginBottom={3}
          />
          <A
            href={blade.learnMoreUrl}
            className="learn-more"
            data-tracking-category="newhome-features-blade"
            data-tracking-action="click"
            data-tracking-label={`features_learn_more-[${blade.learnMoreUrl}]`}
          >
            {blade.learnMoreText ? blade.learnMoreText : 'Learn More'}
          </A>
        </Div>
      )}
    </Col>
  );
};

export const QuoteBlade: React.FC<QuoteBladeProps> = ({ blade }) => {
  type KeyboardEvent = React.KeyboardEvent<'div'>;

  function handleEnter(e: KeyboardEvent, slideFunc: Function) {
    if (e.charCode == 13) {
      slideFunc();
    }
    if (e.keyCode == 13) {
      slideFunc();
    }
  }

  if (blade.quoteCarousel && blade.quoteCarousel.length > 0) {
    const carouselList = [blade].concat(blade.quoteCarousel);
    return (
      <Div backgroundColor="blue4" {...sectionPadding(blade.sectionPadding, blade.contentTypeId)}>
        <Container>
          <Carousel
            transitionMode={'scroll'}
            heightMode="max"
            renderCenterLeftControls={({ previousSlide }) => (
              <Div
                className="dn db-l pointer carousel-arrow"
                tabIndex={0}
                onClick={previousSlide}
                onKeyDown={e => handleEnter(e, previousSlide)}
              >
                <svg
                  fill="none"
                  height="48"
                  viewBox="0 0 48 48"
                  width="48"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m20.9847 40.7775 2.0925-2.1375-13.40251-13.14h33.81751v-3h-33.81752l13.40252-13.13997-2.0925-2.145-17.13002 16.78497z"
                    fill="#0055dc"
                  />
                </svg>
              </Div>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <Div
                className="dn db-l pointer carousel-arrow"
                tabIndex={0}
                onClick={nextSlide}
                onKeyDown={e => handleEnter(e, nextSlide)}
              >
                <svg
                  fill="none"
                  height="48"
                  viewBox="0 0 48 48"
                  width="48"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m27.0153 7.2225-2.0925 2.1375 13.4025 13.14h-33.81749v3h33.81749l-13.4025 13.14 2.0925 2.145 17.13-16.785z"
                    fill="#0055dc"
                  />
                </svg>{' '}
              </Div>
            )}
          >
            {carouselList.map(slide => (
              <Row className="nuka-slide pb7 pb9-l" key={slide.contentfulId}>
                <QuoteSection blade={slide}></QuoteSection>
              </Row>
            ))}
          </Carousel>
        </Container>
      </Div>
    );
  }
  return (
    <Div
      backgroundColor="blue4"
      {...sectionPadding(blade.sectionPadding, blade.contentTypeId)}
      className={blade.logoList || blade.logoListAssetFile ? 'pb4' : 'pb7'}
    >
      <Container>
        <Row>
          <QuoteSection blade={blade}></QuoteSection>
        </Row>
        <LogoList logoList={blade.logoList} logoListAssetFile={blade.logoListAssetFile}></LogoList>
      </Container>
    </Div>
  );
};
