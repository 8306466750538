import * as React from 'react';
import { P, Div } from './Elements';
import { SpaceIndex, SpacingStyleProps, MarginStyleProps } from './Elements/types';

interface SubtitleProps {
  marginBottom?: any; // defaults to 0;
}

export const Subtitle: React.FC<SubtitleProps> = ({ children, marginBottom }) => {
  return (
    <Div
      fontWeight={7}
      fontSize={2}
      textTransform="uppercase"
      color="orange0"
      marginBottom={marginBottom ? marginBottom : 0}
    >
      {children}
    </Div>
  );
};
