import { useEffect, useState } from 'react';
import { getCookie } from '../util/getCookie';

export function useLoggedIn() {
  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    setLoggedIn(getCookie('__cf_logged_in') === '1');
  }, []);

  return isLoggedIn;
}
