import { ContentfulBladeMultipleFeatures } from '../../../contentful/content-types/blades/blade-four-features/blade-four-features.interface';
import { ContentfulBladeSubNav } from '../../../contentful/content-types/blades/blade-sub-nav';
import { ContentfulBladeSubNavMenuItem } from '../../../contentful/content-types/blades/blade-sub-nav-menu-item';
import { ContentfulBladeThreeFeatures } from '../../../contentful/content-types/blades/blade-three-features/blade-three-features.interface';
import { LearningCenterArticleFooter } from '../../../contentful/content-types/learning-center-article-footer';
import { isLearningCenterArticleLink } from '../../../contentful/content-types/learning-center-article-link/learning-center-article-link.interface';
import { LearningCenterArticleLinkedList } from '../../../contentful/content-types/learning-center-article-linked-list';
import { isLearningCenterArticleLinkedList } from '../../../contentful/content-types/learning-center-article-linked-list/learning-center-article-linked-list.interface';
import { LearningCenterArticleSubHeader } from '../../../contentful/content-types/learning-center-article-sub-header';
import { MainFooter } from '../../layout/footer/footer.interfaces';
const slugify = require('slugify');
/*
    This converts the old "ContentfulBladeThreeFeatures" to "ContentfulBladeMultipleFeatures"
    When all three feature blades have been converted in Contentful, this can be removed.
*/

export function getMultipleFeaturesBladeFromThreeFeaturesBlade(
  blade: ContentfulBladeThreeFeatures
): ContentfulBladeMultipleFeatures {
  let result: ContentfulBladeMultipleFeatures = {
    backgroundColor: blade.backgroundColor,
    contentTypeId: 'bladeFourFeatures',
    contentfulId: blade.contentfulId,
    title: blade.sectionTitle,
    features: [],
    bladeStyle: '',
  };
  if (blade.features) {
    result.features = blade.features;
  } else {
    if (result.features) {
      result.features.push({
        contentfulId: 'feature1',
        contentTypeId: 'feature',
        title: blade.title1,
        description: blade.copy1,
        learnMoreUrl: blade.learnMoreUrl1,
        learnMoreText: 'Learn more',
        icon: blade.image1,
        iconAssetFile: blade.imageAssetFile1,
      });

      result.features.push({
        contentfulId: 'feature2',
        contentTypeId: 'feature',
        title: blade.title2,
        description: blade.copy2,
        learnMoreUrl: blade.learnMoreUrl2,
        learnMoreText: 'Learn more',
        icon: blade.image2,
        iconAssetFile: blade.imageAssetFile2,
      });

      result.features.push({
        contentfulId: 'feature3',
        contentTypeId: 'feature',
        title: blade.title3,
        description: blade.copy3,
        learnMoreUrl: blade.learnMoreUrl3,
        learnMoreText: 'Learn more',
        icon: blade.image3,
        iconAssetFile: blade.imageAssetFile3,
      });
    }
  }

  return result;
}

export function getBladeSubnavFromLearningCenterSubHeader(
  header: LearningCenterArticleSubHeader,
  learningCenterString: string
): ContentfulBladeSubNav {
  let result: ContentfulBladeSubNav = {
    contentTypeId: 'bladeSubNav',
    menuTitle: learningCenterString,
    menuItems: [],
  };
  if (header.links) {
    header.links.forEach(link => {
      if (isLearningCenterArticleLinkedList(link)) {
        let subMenuItems: ContentfulBladeSubNavMenuItem[] = [];
        link.links.forEach(subLink => {
          subMenuItems.push({
            contentTypeId: 'bladeSubNavMenuItem',
            linkText: subLink.displayText,
            contentfulId: subLink.contentfulId,
            url: subLink.url,
          });
        });
        result.menuItems.push({
          contentTypeId: 'bladeSubNavMenuItem',
          linkText: link.displayText,
          contentfulId: link.contentfulId,
          subMenuItems: subMenuItems,
        });
      } else if (isLearningCenterArticleLink(link)) {
        result.menuItems.push({
          contentTypeId: 'bladeSubNavMenuItem',
          linkText: link.displayText,
          contentfulId: link.contentfulId,
          url: link.url,
        });
      }
    });
  }
  return result;
}

export function getMainFooterFromLearningCenterFooter(
  footer: LearningCenterArticleFooter,
  mainFooterData: MainFooter
): MainFooter {
  let result: MainFooter = {
    ...mainFooterData,
    // title: 'Learning Center Footer',
    // enable: true,
    // contactField: 'Contact Sales:',
    // copyRight: mainFooterData.copyRight,
    // copyrightYear: '2021',
    // column1Title: '',
    // column1: [],
    column2Title: footer.column1Title,
    column2: [],

    column3Title: footer.column2Title,
    column3: [],

    column4Title: footer.column3Title,
    column4: [],

    column5Title: footer.column4Title,
    column5: [],

    column6Title: footer.column5Title,
    column6: [],
  };

  if (footer.column1) {
    footer.column1.links.forEach(link => {
      result.column2.push({
        title: link.displayText,
        contentfulId: link.contentfulId,
        url: link.url,
        customClassName: '',
        trackingLabel: slugify(link.displayText).toLowerCase(),
      });
    });
  }

  if (footer.column2) {
    footer.column2.links.forEach(link => {
      result.column3.push({
        title: link.displayText,
        contentfulId: link.contentfulId,
        url: link.url,
        customClassName: '',
        trackingLabel: slugify(link.displayText).toLowerCase(),
      });
    });
  }

  if (footer.column3) {
    footer.column3.links.forEach(link => {
      result.column4.push({
        title: link.displayText,
        contentfulId: link.contentfulId,
        url: link.url,
        customClassName: '',
        trackingLabel: slugify(link.displayText).toLowerCase(),
      });
    });
  }

  if (footer.column4) {
    footer.column4.links.forEach(link => {
      result.column5.push({
        title: link.displayText,
        contentfulId: link.contentfulId,
        url: link.url,
        customClassName: '',
        trackingLabel: slugify(link.displayText).toLowerCase(),
      });
    });
  }

  if (footer.column5) {
    footer.column5.links.forEach(link => {
      result.column6.push({
        title: link.displayText,
        contentfulId: link.contentfulId,
        url: link.url,
        customClassName: '',
        trackingLabel: slugify(link.displayText).toLowerCase(),
      });
    });
  }

  // if (footer.column1) {
  //   footer.column1.links.forEach(link => {
  //     result.column6.push({
  //       title: link.displayText,
  //       contentfulId: link.contentfulId,
  //       url: link.url,
  //       customClassName: '',
  //       trackingLabel: slugify(link.displayText).toLowerCase(),
  //     });
  //   });
  // }

  return result;
}
