import React from 'react';
import { H2, Span, A, Div } from './Elements';

export interface PromotionalBannerInterface {
  pillText?: string;
  desktopText?: string;
  linkText?: string;
  linkUrl?: string;
}
const PromotionalBanner: React.FC<PromotionalBannerInterface> = ({
  pillText,
  desktopText,
  linkText,
  linkUrl,
}) => {
  return (
    <Div
      className="flex-ns flex-nowrap-l flex-nowrap-m flex-wrap flex"
      border="all"
      borderColor="gray2"
      alignItems="center"
      padding={1}
    >
      <Span
        className="dib-m dib-l bw2 ttu lh-title"
        display="block"
        color="white"
        fontWeight={6}
        lineHeight="copy"
        padding={1}
        marginRight={1}
        textAlign={'center'}
        backgroundColor="blue1"
        fontSize={1}
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        {pillText}
      </Span>
      <H2
        margin={0}
        className="body-2 mt1 mt0-ns"
        fontSize={2}
        lineHeight="title"
        fontWeight={4}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
      >
        <Div fontWeight={4} display="flex" flexWrap="wrap">
          <Span>
            {desktopText}{' '}
            <A
              href={linkUrl}
              data-tracking-category="promotional-banner"
              data-tracking-action="click"
              data-tracking-label={`promotional_banner_learn_more-[${linkUrl}]`}
              fontWeight={4}
              fontSize={2}
              color="blue1"
              display="inline-flex"
              alignItems="center"
              style={{ marginLeft: 2 }}
              role="link"
            >
              <Span display="block" fontWeight={4}>
                {linkText}{' '}
              </Span>
              <svg
                width="11"
                height="10"
                viewBox="0 0 6 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ margin: '3px 0 0 0' }}
                className="fw4"
              >
                <path
                  d="M1.00003 0.142578L-3.93401e-07 1.14261L4 5.14258L0.000271373 9.14283L1.00003 10.1426L6.00003 5.14258L1.00003 0.142578Z"
                  fill="#0055DC"
                />
              </svg>
            </A>
          </Span>
        </Div>
      </H2>
    </Div>
  );
};

export default PromotionalBanner;
