import React from 'react';
import { Stream as StreamReact, StreamProps as StreamReactProps } from '@cloudflare/stream-react';

interface StreamProps extends StreamReactProps {
  posterTime?: string;
  src: string;
}
export const Stream: React.FC<StreamProps> = ({ posterTime = '0s', src, ...rest }) => {
  return (
    <StreamReact
      {...rest}
      poster={
        rest.poster ||
        `https://videodelivery.net/${src}/thumbnails/thumbnail.jpg?time=${posterTime}&height=720`
      }
      src={src}
    />
  );
};
