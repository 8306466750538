import { LearningCenterArticleLink } from '../learning-center-article-link/learning-center-article-link.interface';

export interface LearningCenterArticleLinkedList {
  contentTypeId: 'learningCenterArticleLinkedList';
  contentfulId: string;
  name: string;
  displayText: string;
  links: LearningCenterArticleLink[];
}

export function isLearningCenterArticleLinkedList(
  data: any
): data is LearningCenterArticleLinkedList {
  return data?.contentTypeId === 'learningCenterArticleLinkedList';
}
